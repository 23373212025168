import React from 'react';
import { Container, Row, Col, } from 'reactstrap';
import { Link } from 'gatsby';

import {toggleLang, getLang} from '../../services/lang';
import Layout from '../../components/Layout';
import RoomsSlider from '../../components/Slices/RoomsSlider';
import * as TXT from '../../res/strings';
import * as TXTEN from '../../res/strings_en';
import * as ICON from '../../res/icons';
import { MASTERSUITE_ROOM, MASTERSUITE_BANO, MASTERSUITE_BED, MASTERSUITE_PASILLO, MASTERSUITE_TERRAZA, MASTERSUITE_ROOM_MOB, MASTERSUITE_BANO_MOB, MASTERSUITE_BED_MOB, MASTERSUITE_PASILLO_MOB, MASTERSUITE_TERRAZA_MOB, } from '../../res/images';

class MasterSuite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
    this.updateWindowDimensions();
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    let RollList =[];
    if (this.state.windowWidth >= 1023) {
      RollList = [
        { img_src: MASTERSUITE_ROOM, id: "roll-1" },
        { img_src: MASTERSUITE_BANO, id: "roll-2" },
        { img_src: MASTERSUITE_BED, id: "roll-3" },
        { img_src: MASTERSUITE_PASILLO, id: "roll-4" },
        { img_src: MASTERSUITE_TERRAZA, id: "roll-5" },
      ];
    } else {
      RollList = [
        { img_src: MASTERSUITE_ROOM_MOB, id: "roll-1" },
        { img_src: MASTERSUITE_BANO_MOB, id: "roll-2" },
        { img_src: MASTERSUITE_BED_MOB, id: "roll-3" },
        { img_src: MASTERSUITE_PASILLO_MOB, id: "roll-4" },
        { img_src: MASTERSUITE_TERRAZA_MOB, id: "roll-5" },
      ];
    }
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" id="start" style={{ marginTop: 100 }}>
					<Container>
            <Row>
							<Col className="text-center title">
								<h1>
									{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME1 : TXT.ROOMS.ROOM_NAME1}
								</h1>
							</Col>
            </Row>
						<RoomsSlider RollList = {RollList}/>
            <Row>
							<Col className="text-center">
								<p>
									{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION1 : TXT.ROOMS.ROOM_DESCRIPTION1}
								</p>
							</Col>
            </Row>
            <Row>
							<Col xs="12" md={{ size: 4, offset: 2 }} >
									{APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE_PPROPERTIES : TXT.ROOMS.TITLE_PPROPERTIES}
							</Col>
              <Col xs="12" md="4">
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_PROPERTY1 : TXT.ROOMS.ROOM1_PROPERTY1}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_PROPERTY2 : TXT.ROOMS.ROOM1_PROPERTY2}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_PROPERTY3 : TXT.ROOMS.ROOM1_PROPERTY3}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_PROPERTY4 : TXT.ROOMS.ROOM1_PROPERTY4}</li>
                </ul>
							</Col>
            </Row>
            <span className="separator_100" />
            <Row>
							<Col xs="12" md={{ size: 2, offset: 2 }}>
									{APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE_AMENITIES : TXT.ROOMS.TITLE_AMENITIES}
							</Col>
              <Col xs="12" md="4" className="has-text-second">
                <ul>
                  <li>
                    <span className="icon"><img src={ICON.WIFI} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES1 : TXT.ROOMS.ROOM1_AMENITIES1}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.MINIBAR} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES3 : TXT.ROOMS.ROOM1_AMENITIES3}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.POOLTOWELS} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES5 : TXT.ROOMS.ROOM1_AMENITIES5}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.IRON} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES7 : TXT.ROOMS.ROOM1_AMENITIES7}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.COUCH} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES9 : TXT.ROOMS.ROOM1_AMENITIES9}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.SHAMPOO} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES11 : TXT.ROOMS.ROOM1_AMENITIES11}<br />
                  </li>
                </ul>
							</Col>
              <Col xs="12" md="4" className="has-text-second">
                <ul>
                  <li>
                    <span className="icon"><img src={ICON.SAT_TV} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES2 : TXT.ROOMS.ROOM1_AMENITIES2}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.TOWEL} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES4 : TXT.ROOMS.ROOM1_AMENITIES4}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.SLEEP} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES6 : TXT.ROOMS.ROOM1_AMENITIES6}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.LAKE} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES8 : TXT.ROOMS.ROOM1_AMENITIES8}<br />
                  </li>
                  <li>
                    <span className="icon"><img src={ICON.SWIMMING} /></span>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_AMENITIES10 : TXT.ROOMS.ROOM1_AMENITIES10}<br />
                  </li>
                </ul>
							</Col>
            </Row>
            <span className="separator_100" />
            <Row>
							<Col xs="12" md={{ size: 4, offset: 2 }}>
									{APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE_FACILITIES : TXT.ROOMS.TITLE_FACILITIES}
							</Col>
              <Col xs="12" md="4">
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_FACILITIES1 : TXT.ROOMS.ROOM1_FACILITIES1}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_FACILITIES2 : TXT.ROOMS.ROOM1_FACILITIES2}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_FACILITIES3 : TXT.ROOMS.ROOM1_FACILITIES3}</li>
                  <li>{APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM1_FACILITIES4 : TXT.ROOMS.ROOM1_FACILITIES4}</li>
                </ul>
							</Col>
            </Row>
            <span className="separator_100" />
            <Row>
							<Col xs="12" md={{ size: 4, offset: 2 }}>
									{APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE_CHECKTIMES : TXT.ROOMS.TITLE_CHECKTIMES}
							</Col>
              <Col xs="12" md="4">
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_CHECKIN : TXT.ROOMS.ROOM_CHECKIN}<br />
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_CHECKOUT : TXT.ROOMS.ROOM_CHECKOUT}<br />
							</Col>
            </Row>
            <span className="separator_100" />
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/reserve">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.CTA_RESERVE : TXT.BUTTON.CTA_RESERVE}
                  </div>
                </Link>
              </Col>
            </Row>
						<Row>
              <Col xl="12" className="text-center">
                <Link to="/rooms">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_ROOMS : TXT.BUTTON.BTN_BACK_ROOMS}
                  </div>
                </Link>
              </Col>
            </Row>
					</Container>
				</div>
      </Layout>
    );
  }
}

export default MasterSuite;